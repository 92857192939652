import * as React from "react"

import OurServices from "../components/ourServices/ourServices"
import Seo from "../components/seo"
import "../assets/css/style.css"
import "../assets/css/layout.css"
import "../assets/css/services-style.css"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { organizationSchema } from "../const/schemas.pl"
import { languages } from "../const/languages"

const ServicesPage = () => <OurServices lang={languages.PL} />

export const Head = () => {
  return (
    <>
      <Seo
        title="AR-Labs.io | Profesjonalna digitalizacja produktów 3D! "
        description="Poszukujesz najlepszych grafików 3D? Sprawdź nasz doświadczony i utalentowany zespół i przenieś z nami swoje produkty do rzeczywistości rozszerzonej."
        canonicalURL="https://staging-area.ar-labs.io/pl/services"
      />
      <script type="application/ld+json">{organizationSchema}</script>
    </>
  )
}

export default ServicesPage
